<template>
  <div class="events">

        <header class="inner-head">
      <div class="flex justify-between ">
        <h2 class="inner-head-title">Events</h2>

        <ul class="pt-1 flex">
              <li>
          <button
            type="button"
            class="action-button hover:opacity-75 mr-4"
            @click="handleList()"
          >  <i class="fas" v-bind:class="[showList ? 'fa-calendar' : 'fa-list'] "></i> 
          {{viewButtonTitle}} </button>
        </li>
        
                 <li>
          <button
            type="button"
            class="action-button hover:opacity-75"
            @click="addNew()"
          >  
          <i class="fas fa-plus"></i>
           Add New</button>
        </li>

        </ul>
      </div>
    </header>

   <p v-if="showMessage" class="save-notice">
        {{ msg }} <i class="far fa-check-circle"></i>
   </p>


    <div class="w-11/12 mx-auto bg-white rounded-lg p-6 mt-6 mb-8  ">
     


    <div v-if="errored" class="empty">
      <p>
        We are not able to retrieve this information at the moment, please try
        back later
      </p>
    </div>


     <div v-if="showList" id="ListContainer" class="w-full h-full pt-4 pl-4 bg-white ">
        <h2 class="text-xl font-semibold mb-4 pl-3">Upcoming Events</h2>

                <div style="width:100%; text-align:center; " class="spin-holder" v-if="loading">
                <i class="spinner fa fa-spinner fa-spin"></i>
                   <p>Loading Events</p>
              </div>

        <div class="p-2 w-11/12 v-else">
          <div
            class="bg-white rounded-lg px-0 mb-6"
            v-for="(event, index) in monthEventList"
            :key="index"
          >
            <button
              @click="showEvent(event)"
              class="
                p-4
                h-full
                w-full
                text-left
                focus:outline-none
                hover:bg-gray-200
                rounded-lg
                shadow-xl
              "
            >
              <i class="fas fa-ellipsis-h float-right opacity-50"></i>

              <div class="opacity-75">
                <div
                  class="
                    text-xs
                    mr-1
                    rounded-lg
                    h-3
                    w-3
                    inline-block
                    bg-opacity-50
                  "
                  :class="getProgramTypeClass(event)"
                ></div>
                {{ parseDate(event.start_date) }} -
                {{ parseDate(event.end_date) }}
              </div>
              <h3 class="font-extrabold text-sm my-2">{{ event.title }}</h3>
              <p class="text-xs opacity-75">{{ event.description.String }}</p>
            </button>
          </div>
        </div>
        <div v-if="noResultsFound" class="ml-4 font-bold w-full cursor-pointer" @click="addNew">
              <p class="mt-1 text-gray-400 text-sm w-11/12 text-center">No events found. Please select another month or click to add a new event.</p>
        </div>
      </div>

        <Calendar
        v-else
          ref="calendar"
          class="custom-calendar max-w-full border-none"
          :masks="masks"
          :attributes="events"
          disable-page-swipe
          is-expanded
          title-position="left"
          @update:to-page="monthMove"
        >
          <template v-slot:day-content="{ day, attributes }">
            <div
              class="
                flex flex-col
                h-full
                z-10
                overflow-hidden
                bg-white
                border border-gray-200
              "
            >
              <span class="day-label text-sm text-gray-600 pl-1">{{
                day.day
              }}</span>
              <div class="flex-grow overflow-y-auto overflow-x-auto h-20">
                <div
                  v-for="(attr, index) in attributes"
                  :key="index"
                  class="text-xs leading-tight overflow-hidden p-1"
                >
                  <button
                    class="
                      w-full
                      rounded
                      py-2
                      px-2
                      text-gray-800
                      bg-opacity-25
                      text-xs
                      hover:bg-gray-200
                    "
                    @click="hiliteEvent(attr.key, index)"
                    :class="attr.customData.class"
                  >
                    {{ attr.customData.title }}
                  </button>
                </div>
              </div>
            </div>
          </template>
        </Calendar>

    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import Calendar from 'v-calendar/lib/components/calendar.umd'
import { eventMixin } from "@/mixins/eventMixin.js";

export default {
  name: "events",
    mixins: [eventMixin],
  computed: {
    noResultsFound() {
      return !this.loading && this.monthEventList <= 0
    },
    viewButtonTitle() {
      return this.showList ? 'Calendar View' : 'List View'
    }
  },
  data() {
    return {
      masks: {
        weekdays: "WWW",
      },
      showSearch: false,
      showFilter: false,
      showMessage: false,

      showList: false,
      sortBy: "date",
      sortDirection: "asc",
      previousEventKey: -1,
      btnText: "Hide List",
    };
  },
  watch: {
    // sortBy: function () {
    //   this.sortedEvents();
    // },
    // sortDirection: function () {
    //   this.sortedEvents();
    // },
  },
  methods: {
    sort(s) {
      if (s === this.sortBy) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      }
      this.sortBy = s;
    },
    iconDirection(sortBy) {
      if (this.sortBy === sortBy) {
        if (this.sortDirection === "desc") {
          return "fa-sort-up";
        } else {
          return "fa-sort-down";
        }
      }
      return "fa-sort";
    },
    sortedEvents() {
      if (this.sortBy === "events") {
        this.events = this.$orderBy(
          this.events,
          (item) => item.customData.title,
          this.sortDirection
        );
      } else {
        this.events = this.$orderBy(
          this.events,
          (item) => item.dates,
          this.sortDirection
        );
      }
    },
    addNew() {
      let event = {};
      this.$router.push({
        name: "EventView",
        params: { event: event, newAddition: true },
      });
    },
    hiliteEvent(key) {
      var selectedEvent = this.rawEventData.find((e) => e.identifier === key);
      if (selectedEvent != undefined) {
        this.showEvent(selectedEvent);
      }
    },
    showEvent(event) {
      this.$router.push({
        name: "EventView",
        params: { event: event, newAddition: false },
      });
    },
    handleList() {
      this.showList = !this.showList;
    },
    monthMove(page) {
      this.currentMonth = page.month;
      this.currentYear = page.year;
    },
    changeMonth(start, end, current) {
      //console.log('changeMonth', start.format(), end.format(), current.format())
    },
    eventClick(event, jsEvent, pos) {
      // console.log('eventClick', event, jsEvent, pos)
    },
    dayClick(day, jsEvent) {
      // console.log('dayClick', day, jsEvent)
    },
    moreClick(day, events, jsEvent) {
      // console.log('moreCLick', day, events, jsEvent)
    },

  },
    components: {
    Calendar,
  },
};
</script>

<style >
.vc-title {
  @apply font-bold !important;
}

.vc-weekday {
  @apply bg-gray-200 text-gray-600 py-4 mt-4 text-xs !important;
}

.vc-day-content:hover:not(.is-disabled) {
  @apply bg-red-400 text-white;
}

.vc-day-content.is-disabled {
  @apply pointer-events-none;
}

.vc-day-content.is-disabled:hover {
  @apply bg-transparent;
}

.vc-day.is-not-in-month *:not(.is-disabled) {
  @apply opacity-50 text-gray-700 pointer-events-none;
}

.vc-day.is-not-in-month .is-disabled {
  @apply opacity-50 text-gray-400 pointer-events-none;
}
</style>
