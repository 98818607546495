import { EventService } from "@/services/event.service"
import { eventTypes } from "@/assets/js/utils";
import { mapGetters } from "vuex";
import moment from "moment";
export const eventMixin = {
    data() {
        return {
            rawEventData: [],
            events: [],
            loading: true,
            errored: false,
            types: eventTypes(),
            currentYear: -1,
            currentMonth: -1,
        };
    },
    created() {
        this.loading = true;
        this.fetchData();
    },
    mounted() {
        this.currentMonth = new Date().getMonth() + 1;
        this.currentYear = new Date().getFullYear();
      },
    computed: {
        ...mapGetters({

        }),
        monthEventList() {
            let vm = this;
            let monthlyEvents = this.rawEventData.filter((d) => {
                return (
                    (moment(d.start_date).month() === vm.currentMonth - 1 &&
                        moment(d.start_date).year() === vm.currentYear) ||
                    (moment(d.end_date).month() === vm.currentMonth - 1 &&
                        moment(d.end_date).year() === vm.currentYear)
                );
            });
            return this.$orderBy(monthlyEvents, ["start_date", "program_type", "title"]);
        },
        dashboardEventList() {
            return this.monthEventList.splice(0, 5)
        }
    },
    methods: {
        fetchData() {

            EventService.getAll()
                .then((response) => this.buildList(response.data))
                .catch((err) => {
                    console.log("events " + err);
                    this.errored = true;
                })
                .finally(() => (this.loading = false));
        },
        buildList(data) {
            this.loading = false
            const vm = this;
            vm.rawEventData = data;
            for (var i = 0; i < data.length; i++) {
                var obj = data[i];
                let type = vm.getProgramType(obj);

                vm.events.push({
                    key: obj.identifier,
                    customData: {
                        title: obj.title,
                        class: type != undefined ? type.class : "bg-red-200",
                    },
                    dates: [
                        {
                            // start: new Date(this.thisMonthYear, this.thisMonth, 2),
                            // end: new Date(this.thisMonthYear, this.thisMonth, 4),
                            start: new Date(obj.start_date),
                            end: new Date(obj.end_date),
                        },
                    ],
                });
            }
        },
        formatDate(date, format) {
            return moment(date).format(format);
        },
        parseDate(date) {
            return moment(date).format("DD/MM/YYYY, h:mm a");
        },
        getProgramType(event) {
            return this.types.find((t) => t.value === event.program_type);
          },
          getProgramTypeClass(event) {
            let type = this.getProgramType(event);
            return type != undefined ? type.class : "bg-red-200";
          },

    }
};
